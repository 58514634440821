<template>
  <div>
    <div class="title">
      <div>门店设置</div>
    </div>
    <div class="tab">
      <base-form ref="baseForm" :btns="['search']" :formList="formList" @search="search"></base-form>
    </div>
    <div class="table" ref="tableBox">
      <IviewTable
        :height="tableHeight"
        :border="true"
        :columns="tableColumns"
        :data="tableData"
        :context="self"
        backStage
        :page="page"
        @on-change="pageChange"
        @on-page-size-change="pageSizeChange"
      ></IviewTable>
    </div>

    <Modal
      v-model="showModal"
      :mask-closable="false"
      footer-hide
      :title="`设置比例-${activeStore.storeName}`"
    >
      <div >
        <form-row label="类型">
          <RadioGroup v-model="activeStore.type" type="button" button-style="solid">
            <Radio :label="1">按比例</Radio>
            <Radio :label="2">按固定总金额</Radio>
          </RadioGroup>
        </form-row>
        <form-row label="比例" v-if="activeStore.type==1">
          <Input v-model="activeStore.rate" placeholder="请输入比例(例如：80)" style="width: 300px;">
            <span slot="append">%</span>
          </Input>
        </form-row>
        <form-row label="金额" v-if="activeStore.type==2">
          <InputNumber v-model.trim="activeStore.total" class="ipt" clearable placeholder="请输入金额" style="width: 300px;">
          </InputNumber>
          <span style="margin-left: 10px;">元</span>
        </form-row>
        <form-row>
          <div style="text-align: right;">
            <Button type="primary" class="btn-save" @click="seveSet">设置</Button>
          </div>
        </form-row>
      </div>

    </Modal>

    <Modal
      v-model="showModal2"
      :mask-closable="false"
      footer-hide
      :title="`修改密码-${activeStore.storeName}`"
    >
      <update-pwd ref="UpdatePwd" :orgId="activeStore.orgId" @close="showModal2=false"></update-pwd>
    </Modal>
  </div>
</template>

<script>
import { formatTime } from "@/utils";
import baseForm from '@/components/baseForm'
import FormRow from '@/components/formRow'
import IviewTable from '_c/iview/IviewTable'
import UpdatePwd from '@/components/password/update-pwd.vue'
import mixin from '@/mixins'
export default {
  name: '',
  components: {
    IviewTable,
    UpdatePwd,
    FormRow,
    baseForm
  },
  mixins: [mixin],
  data() {
    return {
      self: this,
      modalFormStatus: false,
      showModal: false,
      showModal2: false,
      activeStore: {},
      tableHeight:0,
      formValidate: {
        storeName: ''
      },
      formList: [
      ],
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      rowData: {},
      tableData: [],
      tableColumns: [
        {
          title: '门店名称',
          key: 'storeName',
        },
        {
          title: '门店号',
          key: 'storeCode',
        },
        {
          title: '类型',
          key: 'type$value',
          width: 200
        },
        {
          title: '比例/金额',
          key: 'rate$value',
          width: 200
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 200,
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  style: { color: '#FFC300', cursor: 'pointer', marginRight: '20px' },
                  on: {
                    click: () => {
                      this.updateRate(params.row)
                    },
                  },
                },
                '设置比例'
              ),
              h(
                'span',
                {
                  style: { color: '#FFC300', cursor: 'pointer' },
                  on: {
                    click: () => {
                      this.updatePassword(params.row)
                    },
                  },
                },
                '修改密码'
              )
            ])
          },
        },
      ],
    }
  },
  mounted() {
    this.search({storeName:''})
    // this.$refs.baseForm.setData(this.formValidate)
    this.getElementHeight();
    // 监听页面rezise事件
    window.addEventListener("resize", this.getElementHeight);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("resize", this.getElementHeight);
    });
  },
  methods: {
    getElementHeight() {
      const element = this.$refs.tableBox;
      const height = element.offsetHeight; // 或者使用其他的属性，如clientHeight、scrollHeight等
      console.log('Element height:', height);
      // 其他逻辑处理
      this.tableHeight = height - 40
    },
    pageChange(page){
      this.page.current = page
      this.formValidate.page = page
      this.getStoreList(this.formValidate)
    },
    pageSizeChange(pageSize){
      this.page.current = 1
      this.page.pageSize = pageSize
      this.formValidate.page = 1
      this.formValidate.pageSize = pageSize
      this.getStoreList(this.formValidate)
    },
    search(data) {
      this.page.current = 1
      let params = {
        ...data,
        page: this.page.current,
        pageSize: this.page.pageSize,
      }
      if (data.orgId) {
        params.storeName = this._storeList.find(v => v.value === data.orgId).label
      }
      this.formValidate = params
      this.getStoreList(params)
    },
    getStoreList(params = {}) {
      this.$api.order.getStoreList(params).then((res) => {
        res.data.forEach(v=>{
          v.type$value = "-"
          v.rate$value = "-"
          if (v.type) {
            v.type$value = v.type==1?'按比例':v.type==1?'按固定总金额':'-'
            if (v.type==1) {
              v.type$value = '按比例'
              v.rate$value = `${v.rate}%`
            }
            if (v.type==2) {
              v.type$value = '按固定总金额'
              v.rate$value = `${v.total}元`
            }
          }
          
        })

        this.tableData = res.data
        this.page.total = res.count
      })
    },
    updatePassword(row){
      this.showModal2 = true
      this.activeStore = row
    },
    updateRate(row){
      this.$api.order.getSetting({orgId:row.orgId}).then(res=>{
        this.showModal = true
        let { type, rate, total } = res.data
        if(type==0){
          rate = rate || ''
          total = total || ''
          type = 1
        }
        this.activeStore = Object.assign({},row,{ type, rate, total })
      })
    },
    seveSet(){
      let { type, rate, total, orgId } = this.activeStore
      if(!type){
        this.$Message.warning('请选择类型');
        return
      }
      if(type==1 && !rate && rate!==0){
        this.$Message.warning('请设置比例');
        return
      }
      if(type==2 && !total && total!==0){
        this.$Message.warning('请设置金额');
        return
      }
      if(type==1){
        total = 0
      }
      if(type==2){
        rate = 0
      }
      this.$api.order.saveSetting({ type, rate, total, orgId }).then(res=>{
        if(res.code==200){
          this.$Message.success('设置成功！');
        }
      })
    }
  },
}
</script>

<style lang="less" scoped>
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 28px;
  font-size: 24px;
  .md-download-btn {
    font-size: 16px;
  }
}
.tab {
  background: #ffffff;
}
.table {
  background: #ffffff;
  padding: 0 30px;
  position: relative;
  height: calc(100vh - 155px);
  /deep/.i-page.fixed {
    position: absolute;
    bottom: -40px;
  }
  .btn {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
}
// .btn-save {
//   width: 80px;
//   height: 30px;
//   background: #85c989;
//   box-shadow: 0px 2px 2px 0px #448247, 0px 0px 1px 0px #b4ffb9;
//   font-size: 14px;
//   font-weight: bold;
//   color: #ffffff;
//   text-shadow: 0px 1px 1px #163124, 0px 3px 5px #bcffca;
//   margin-right: 11px;
//   border: none;
// }
</style>
